const switchNetworkNumber = 56;
const switchNetworkParam = {
  chainId: "0x38",
  chainName: "Binance Smart Chain Mainnet",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18,
  },
  rpcUrls: [
    "https://bsc-dataseed3.ninicoin.io",
    "https://bsc-dataseed3.defibit.io",
    "https://bsc-dataseed3.binance.org",
    "https://bsc-dataseed.binance.org",
    "https://bsc-dataseed2.binance.org",
    "https://bsc-dataseed1.ninicoin.io",
    "https://bsc-dataseed2.ninicoin.io",
    "https://bsc-dataseed4.binance.org",
    "https://bsc-dataseed1.defibit.io",
  ],
  blockExplorerUrls: ["https://bscscan.com"],
};

export { switchNetworkNumber, switchNetworkParam };
