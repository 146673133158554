// 4: Rinkeby 1: Ethereum 5: Goerli 42: Kovan 56: BSC 97: BSC Testnet 137: Polygon 80001: Polygon Testnet
const switchNetworkNumber = 5;
const switchNetworkParam = {
  chainId: "0x5",
  chainName: "Ethereum Mainnet",
  nativeCurrency: {
    name: "GoerliETH",
    symbol: "GoerliETH",
    decimals: 18,
  },
  rpcUrls: ["https://goerli.infura.io/v3"],
  blockExplorerUrls: ["https://goerli.etherscan.io"],
};

module.exports = {
  switchNetworkNumber,
  switchNetworkParam,
};
